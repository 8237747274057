import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import logo from '../assets/images/black-alsatian-web-design.svg'

const Header = props => (
  <header id='header' className='alt'>
    <Link to='/' className='logo' aria-label='Logo Link to Home Page'>
      <img src={logo} alt='Web design &amp; Online Marketing Agency' />
    </Link>
    <nav>
      <button
        className='menu-link'
        onClick={props.onToggleMenu}
        aria-label='Menu Link'
      >
        {/* Menu */}
      </button>
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
