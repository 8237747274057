import React, { Component } from 'react'
import Obfuscate from 'react-obfuscate'
import Form from './Form'

class Contact extends Component {
  render() {
    return (
      <section id='contact'>
        <div className='inner'>
          <section>
            <Form />
          </section>
          <section className='split'>
            <section>
              <div className='contact-method'>
                <span className='icon alt fa-envelope'></span>
                <h3>Email</h3>
                <Obfuscate
                  email='info@blackalsatian.com'
                  headers={{
                    subject: 'Enquiry from Black Alsatian',
                    body: 'Down with the machines!',
                  }}
                />
              </div>
            </section>
            <section>
              <div className='contact-method'>
                <span className='icon alt fa-phone'></span>
                <h3>Phone</h3>
                <span>
                  <Obfuscate tel='+27 87 821 7172' />
                </span>
              </div>
            </section>
            <section>
              <div className='contact-method'>
                <span className='icon alt fa-home'></span>
                <h3>Address</h3>
                <span>
                  PO Box 211126, The Fig Tree,
                  <br />
                  Port Elizabeth, 6033,
                  <br />
                  South Africa
                </span>
              </div>
            </section>
          </section>
        </div>
      </section>
    )
  }
}

export default Contact
