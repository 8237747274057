import React from 'react'
import { navigate } from 'gatsby'

const CookieCard = ({
  // header,
  message,
  onClick,
  button,
  privacyUrl,
  privacyText,
  // visible,
}) => {
  // const cardVisible = {
  //   display: visible ? 'block' : 'none',
  // }
  return (
    // <div className='cookieCard' style={cardVisible}>
    <div className='cookieCard'>
      <div>
        {/* <h5>{header}</h5> */}
        <p>{message}</p>
        <div>
          <button onClick={onClick} className='small'>
            {button}
          </button>
          {privacyUrl && (
            <button onClick={() => navigate(privacyUrl)} className='small'>
              {privacyText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CookieCard
