import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { Link } from 'gatsby'

const year = new Date().getFullYear()

const Footer = (props) => (
  <footer id='footer'>
    <div className='inner'>
      <ul className='icons'>
        <li>
          <OutboundLink
            href='http://www.twitter.com/BlackAlsatian'
            className='icon alt fa-twitter'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Twitter'
          >
            <span className='label'>Twitter</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href='http://www.facebook.com/blackalsatian'
            className='icon alt fa-facebook'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Facebook'
          >
            <span className='label'>Facebook</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href='https://www.instagram.com/theblackalsatian/'
            className='icon alt fa-instagram'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Instagram'
          >
            <span className='label'>Instagram</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href='https://wa.me/27605036601?text=Hi,%20I%20was%20just%20browsing%20your%20website%20and...'
            className='icon alt fa-whatsapp'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Whatsapp'
          >
            <span className='label'>Whatsapp</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href='https://www.linkedin.com/company/blackalsatian'
            className='icon alt fa-linkedin'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='LinkedIn'
          >
            <span className='label'>LinkedIn</span>
          </OutboundLink>
        </li>
      </ul>
      <ul className='copyright'>
        <li>Copyright &copy; 2003 - {year}</li>
        <li>
          <OutboundLink
            href='https://www.blackalsatian.co.za/'
            title='Web Development &amp; Online Marketing Agency'
            aria-label='Web Development &amp; Online Marketing Agency'
          >
            Black Alsatian
          </OutboundLink>
        </li>
        <li>
          <Link
            to='/privacy-policy/'
            title='Privacy Policy'
            aria-label='Privacy Policy'
          >
            Privacy Policy
          </Link>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
