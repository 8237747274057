import React, { Component } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import { FormErrors } from './FormErrors'

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      website_id: `${process.env.GATSBY_BA_SITEID}`,
      name: '',
      number: '',
      email: '',
      message: '',
      status: 'lead',
      page: typeof window !== 'undefined' && window.location.pathname,
      traffic_source: typeof window !== 'undefined' && window.location.origin,
      tags: 'enquiry',
      privacy_policy: 0,
      formErrors: {
        name: '',
        number: '',
        email: '',
        message: '',
        privacy_policy: '',
      },
      nameValid: false,
      numberValid: false,
      emailValid: false,
      messageValid: false,
      privacy_policy_valid: false,
      formValid: false,
      sentSuccessful: '',
    }

    // this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // handleChange(e) {
  //   const target = e.target
  //   const value = target.value
  //   const name = target.name
  //   this.setState({
  //     [name]: value,
  //   })
  // }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const checked = e.target.checked
    this.setState(
      {
        [name]: name === 'privacy_policy' ? checked : value,
      },
      () => {
        this.validateField(name, value)
      }
    )
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let nameValid = this.state.nameValid
    let numberValid = this.state.numberValid
    let emailValid = this.state.emailValid
    let messageValid = this.state.messageValid
    let privacy_policy_valid = this.state.privacy_policy_valid

    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 3
        fieldValidationErrors.name = nameValid ? '' : ' is invalid'
        break

      case 'number':
        numberValid = value.length >= 9
        fieldValidationErrors.number = numberValid ? '' : ' is invalid'
        break

      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailValid ? '' : ' is invalid'
        break

      case 'message':
        messageValid = value.length >= 10
        fieldValidationErrors.message = messageValid ? '' : ' is too short'
        break

      case 'privacy_policy':
        privacy_policy_valid = true
        fieldValidationErrors.message = privacy_policy_valid
          ? ''
          : ' needs to be accepted'
        break

      default:
        break
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        numberValid: numberValid,
        emailValid: emailValid,
        messageValid: messageValid,
        privacy_policy_valid: privacy_policy_valid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.nameValid &&
        this.state.numberValid &&
        this.state.emailValid &&
        this.state.messageValid &&
        this.state.privacy_policy_valid,
    })
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error'
  }

  sendGA(context) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Development')
      console.log('GTag fired!')
    } else {
      // console.log('Production')
      if (typeof window !== 'undefined') {
        window.gtag('event', context)
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.sendGA('enquiry')
    console.log(this.state)
    axios({
      method: 'post',
      url: `${process.env.GATSBY_API_URL}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(this.state),
    }).then(
      (response) => {
        // console.log(response)
        this.setState({
          sentSuccessful: 'Thanks for the enquiry. Chat soon!',
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div
            className={`field half first ${this.errorClass(
              this.state.formErrors.name
            )}`}
          >
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              name='name'
              id='name'
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
          <div
            className={`field half first ${this.errorClass(
              this.state.formErrors.number
            )}`}
          >
            <label htmlFor='number'>Number</label>
            <input
              type='tel'
              name='number'
              id='number'
              value={this.state.number}
              onChange={this.handleChange}
            />
          </div>
          <div
            className={`field ${this.errorClass(this.state.formErrors.email)}`}
          >
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              name='email'
              id='email'
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
          <div
            className={`field ${this.errorClass(
              this.state.formErrors.message
            )}`}
          >
            <label htmlFor='message'>Message</label>
            <textarea
              name='message'
              id='message'
              rows='6'
              value={this.state.message}
              onChange={this.handleChange}
            />
          </div>
          <div className='small' style={{ marginBottom: '2rem' }}>
            By submitting this form you confirm that you have read and
            understood Black Alsatian's{' '}
            <Link
              to='/privacy-policy/'
              title='Privacy Policy'
              aria-label='Privacy Policy'
            >
              Privacy Policy
            </Link>{' '}
            and you understand that the information you submit is saved for the
            sole purpose of contacting you regarding your query.
          </div>
          <div
            className={`field ${this.errorClass(
              this.state.formErrors.privacy_policy
            )}`}
          >
            <input
              type='checkbox'
              name='privacy_policy'
              id='privacy_policy'
              // defaultChecked={false}
              checked={this.state.privacy_policy}
              // value={this.state.privacy_policy}
              onChange={this.handleChange}
            />
            <label htmlFor='privacy_policy'>
              {' '}
              I have read and understood the privacy policy.
            </label>
          </div>
          <div>
            {this.state.formErrors && (
              <FormErrors formErrors={this.state.formErrors} />
            )}
          </div>
          {this.state.sentSuccessful && (
            <div className='messageSent'>
              <p>{this.state.sentSuccessful}</p>
            </div>
          )}

          <ul className='actions'>
            <li>
              <input
                type='submit'
                value='Send Message'
                className='special'
                disabled={!this.state.formValid}
              />
            </li>
          </ul>
        </form>
      </>
    )
  }
}

export default Form
